import { Component, OnInit } from '@angular/core';    
import { Router } from '@angular/router';    
import { LoginService } from '../login.service';    
 import { FormsModule } from '@angular/forms';    
 import { AppComponent } from "../app.component";
@Component({    
  selector: 'app-login',    
  templateUrl: './login.component.html',    
  styleUrls: ['./login.component.css']    
})    
export class LoginComponent {    
  model : any={};    
  errorMessage:string;    
  constructor(private router:Router,private LoginService:LoginService,private appComponent:AppComponent) { }    
  ngOnInit() {    
    sessionStorage.removeItem('username');    
    sessionStorage.clear();    
  }    
  login(){    
    //debugger;    
    this.LoginService.Login(this.model).subscribe(    
      data => {    
        //debugger;    
        if(data.message=="success")    
        {       
          localStorage.setItem('userInfo',JSON.stringify(data.response.login));
          this.appComponent.isUserLoggedIn = true;
          this.router.navigate(['/dashboard']);    
         // debugger;    
        }    
        else{    
          this.errorMessage = data.message;    
        }    
      },    
      error => {    
        this.errorMessage = error.message;    
      });    
  };
      
 }