import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormGroup , FormControl , ReactiveFormsModule,FormsModule }   from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './auth.guard';
import { InstanceDetailComponent } from './instance-detail/instance-detail.component';
import { NotifierModule } from "angular-notifier";
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { AwsInstancesComponent } from './aws-instances/aws-instances.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { AwsGroupsComponent } from './aws-groups/aws-groups.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AwsSchedularComponent } from './aws-schedular/aws-schedular.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    InstanceDetailComponent,
    AwsInstancesComponent,
    SidebarComponent,
    HeaderComponent,
    AwsGroupsComponent,
    AwsSchedularComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    NgbModule,
    HttpClientModule,
    Ng4LoadingSpinnerModule.forRoot(),

    NotifierModule.withConfig({
			position: {
        horizontal: {
          /**
           * Defines the horizontal position on the screen
           * @type {'left' | 'middle' | 'right'}
           */
          position: 'right',
          /**
           * Defines the horizontal distance to the screen edge (in px)
           * @type {number}
           */
          distance: 12
      
        },
      
        vertical: {
      
          /**
           * Defines the vertical position on the screen
           * @type {'top' | 'bottom'}
           */
          position: 'top',
      
          /**
           * Defines the vertical distance to the screen edge (in px)
           * @type {number}
           */
          distance: 12,
      
          /**
           * Defines the vertical gap, existing between multiple notifications (in px)
           * @type {number}
           */
          gap: 10
      
        }
      
      }
		})
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
