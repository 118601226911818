import { Component, OnInit,ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { NotifierService } from "angular-notifier";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('closebutton',{static: true}) closebutton;
  errorMessage: string;
  awsAccessKeys: any = [];
  awsKeyForm: FormGroup;

  awsKeyFormSubmitted = false;
  private readonly notifier: NotifierService;
  constructor(private DataService: DataService, private notifierService: NotifierService, private spinnerService: Ng4LoadingSpinnerService, private formBuilder: FormBuilder) {
    this.notifier = notifierService;

  }

  ngOnInit() {
    this.awsKeyForm = this.formBuilder.group({
      awsKeyName: ['', Validators.required],
      awsKeyDescription: ['', Validators.required],
      awsAccessKey: ['', Validators.required],
      awsSecretKey: ['', [Validators.required]],
      awsRegion: ['', Validators.required]
    });
    this.callGetAllAwsKeys();
    
  }
  get f() { return this.awsKeyForm.controls; }

  addAwsKey() {
    
    this.awsKeyFormSubmitted = true;

    // stop here if form is invalid
    if (this.awsKeyForm.invalid) {
      return;
    }
    this.spinnerService.show();
    this.DataService.addUserAwsKeys(this.awsKeyForm.value).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          
          //this.awsAccessKeys = data.response.awsAccessKeys;
          this.notifier.notify("success", "AWS key saved.");
          this.closebutton.nativeElement.click();
          this.callGetAllAwsKeys();
          //location.reload();
        }
        else {
          this.spinnerService.hide();
          this.errorMessage = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });


  }
  callGetAllAwsKeys()
  {
    this.spinnerService.show();
    this.DataService.getAwsAccessKeys().subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          this.awsAccessKeys = data.response.awsAccessKeys;
        }
        else {
          this.errorMessage = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });
  }
  deleteAwsKey(guid)
  {
    this.spinnerService.show();
    this.DataService.deleteUserAwsKeys(guid).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          //this.awsAccessKeys = data.response.awsAccessKeys;
          this.notifier.notify("success", "AWS key deleted.");
          this.callGetAllAwsKeys();
        }
        else {
          this.spinnerService.hide();
          this.errorMessage = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });

  }
}
