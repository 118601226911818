import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { NotifierService } from "angular-notifier";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-aws-schedular',
  templateUrl: './aws-schedular.component.html',
  styleUrls: ['./aws-schedular.component.css'],
})
export class AwsSchedularComponent implements OnInit {

  @ViewChild('closebutton', { static: true }) closebutton;
  @ViewChild('editclosebutton', { static: true }) editclosebutton;

/*   time = {hour: 13, minute: 30};
 */  awsSchedulers: any = [];
  awsScheduleForm: FormGroup;
  awsScheduleFormSubmitted = false;
  schedule_by = [{
    id: 1,
    title: "Group"
  },
  {
    id: 2,
    title: "Instances"
  }]
  turn_status = [{
    id: 1,
    title: "ON"
  },
  {
    id: 0,
    title: "OFF"
  }]
  days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'];
  showInstanceList = false;
  showGroupList = false;
  groupList: any;
  instanceList: any;
  awsInstanceLists: any;
  awsInstanceGroupLists: any;
  private readonly notifier: NotifierService;
  constructor(private DataService: DataService, private notifierService: NotifierService, private spinnerService: Ng4LoadingSpinnerService, private formBuilder: FormBuilder) {
    this.notifier = notifierService;

    this.awsScheduleForm = this.formBuilder.group({
      schedule_by: ['', Validators.required],
      scheduler_name: ['', Validators.required],
      group_id: [],
      instance_id: [],
      turn_status: ['', Validators.required],
      time: ['', Validators.required],
      day: ['', Validators.required],
      guid: []
    });
  }
  get f() { return this.awsScheduleForm.controls; }

  ngOnInit() {
    this.callgetAllAwsScheduler();

  }
  resetForm() {
    this.showInstanceList = false;
    this.showGroupList = false;
    this.awsScheduleForm.reset();
  }
  changeSchedule(event) {
    if (event.target.value == 1) {
      this.awsScheduleForm.patchValue({

        instance_id: null

      });


      this.showInstanceList = false;
      this.showGroupList = true;
      this.DataService.getAwsInstanceGroups().subscribe(
        data => {
          this.spinnerService.hide();
          //debugger;    
          if (data.message == "success") {
            this.awsInstanceGroupLists = data.response.awsInstanceGroups;
          }
          else {
            this.notifier.notify("error", data.message);
          }
        },
        error => {
          this.spinnerService.hide();
          this.notifier.notify("error", error.message);
        });
    } else if (event.target.value == 2) {
      this.awsScheduleForm.patchValue({

        group_id: null

      });
      this.showInstanceList = true;
      this.showGroupList = false;
      this.DataService.getAllAwsInstancesForSchedular().subscribe(
        data => {
          this.spinnerService.hide();
          //debugger;    
          if (data.message == "success") {
            var $name = "";
            var $tags = [];
            data.response.instances.forEach(instance => {
              var $name = "";
              var $tmp_tags = [];
              // console.log(instance.tags," => ",Object.keys(JSON.parse(instance.tags)).length);
              if (Object.keys(JSON.parse(instance.tags)).length > 0) {
                $tags = JSON.parse(instance.tags);
                $tags.forEach(tag => {
                  if (tag.Key == 'Name') {
                    $name = tag.Value;
                  } else {
                    $tmp_tags.push(tag.Value);
                  }
                });
              } else {
                $tmp_tags.push(instance.key_name);
                $name = instance.key_name;
              }
              instance.instance_title = $name;


            });
            this.awsInstanceLists = data.response.instances;
          }
          else {
            this.notifier.notify("error", data.message);
          }
        },
        error => {
          this.spinnerService.hide();
          this.notifier.notify("error", error.message);
        });
    } else {
      this.showInstanceList = false;
      this.showGroupList = false;
    }

  }
  onSubmit() {
    this.awsScheduleFormSubmitted = true;

    // stop here if form is invalid
    if (this.awsScheduleForm.invalid) {
      return;
    }
    this.spinnerService.show();
    this.DataService.addAwsScheduler(this.awsScheduleForm.value).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {

          //this.awsAccessKeys = data.response.awsAccessKeys;
          this.notifier.notify("success", "Scheduler saved.");
          this.closebutton.nativeElement.click();
          this.awsScheduleForm.reset();
          this.callgetAllAwsScheduler();
          /* location.reload(); */
        }
        else {
          this.spinnerService.hide();
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });

  }

  callgetAllAwsScheduler() {
    this.spinnerService.show();
    this.DataService.getAllAwsScheduler().subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {

          data.response.scheduler.forEach((scheduler) => {
           // console.log(scheduler.group_id);
            if(scheduler.schedule_by == 1)
            {
              this.DataService.getGroupName(scheduler.group_id).subscribe(
                data => {
                  var instance_group_name = [];
                  if (data.message == "success") {
                    data.response.group_names.forEach((group_name) => {
                      instance_group_name.push(group_name.name);
                    });
                    scheduler.group_ids = instance_group_name;
                  }
                 
                },
                error => {
                  this.spinnerService.hide();
                  this.notifier.notify("error", error.message);
                });
            }else if(scheduler.schedule_by == 2){
              this.DataService.getInstanceName(scheduler.instance_id).subscribe(
                data => {
                  if (data.message == "success") {
                    var $tags = [];
                    var $name = [];
                    data.response.instance_names.forEach((instance_name) => {
                     
                      // console.log(instance.tags," => ",Object.keys(JSON.parse(instance.tags)).length);
                      if (Object.keys(JSON.parse(instance_name.tags)).length > 0) {
                        $tags = JSON.parse(instance_name.tags);
                        $tags.forEach(tag => {
                          if (tag.Key == 'Name') {
                            $name.push(tag.Value);
                          }
                        });
                      } else {
                        $name = instance_name.key_name;
                      }
                     
                     
                    });
                    scheduler.instance_ids = $name;
                  }
                 
                },
                error => {
                  this.spinnerService.hide();
                  this.notifier.notify("error", error.message);
                });
            }
           /*  var instance_id_names = [];
            if (scheduler.instance_ids) {
              scheduler.instance_ids = scheduler.instance_ids.split('],[');
              instance_id_names.push(JSON.parse(scheduler.instance_ids));
              if (instance_id_names) {
                var instance_id_names_array = [];
                instance_id_names.forEach((instance_id_name) => {
                  instance_id_name.forEach((tmp_instance_id) => {
                    if (tmp_instance_id.Key == "Name") {
                      instance_id_names_array.push(tmp_instance_id.Value);

                    }
                  })
                })
                scheduler.instance_ids = instance_id_names_array;
              }
            } */


          /*  if (scheduler.group_ids) {
              scheduler.group_ids = scheduler.group_ids.split(',');
            } */


          });
          this.awsSchedulers = data.response.scheduler;
        }
        else {
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });
  }
  editModal(awsSchedule) {
    this.awsScheduleForm.reset();
    var group_ids: any;
    var instance_ids: any;
    var editdays: any;
    if (awsSchedule.schedule_by == 1) {
      this.showInstanceList = false;
      this.showGroupList = true;
      this.DataService.getAwsInstanceGroups().subscribe(
        data => {
          this.spinnerService.hide();
          //debugger;    
          if (data.message == "success") {
            this.awsInstanceGroupLists = data.response.awsInstanceGroups;
          }
          else {
            this.notifier.notify("error", data.message);
          }
        },
        error => {
          this.spinnerService.hide();
          this.notifier.notify("error", error.message);
        });

      group_ids = awsSchedule.group_id.split(',');
    }
    else if (awsSchedule.schedule_by == 2) {
      this.showInstanceList = true;
      this.showGroupList = false;
      this.DataService.getAllAwsInstancesForSchedular().subscribe(
        data => {
          this.spinnerService.hide();
          //debugger;    
          if (data.message == "success") {
            var $name = "";
            var $tags = [];
            data.response.instances.forEach(instance => {
              var $name = "";
              var $tmp_tags = [];
              // console.log(instance.tags," => ",Object.keys(JSON.parse(instance.tags)).length);
              if (Object.keys(JSON.parse(instance.tags)).length > 0) {
                $tags = JSON.parse(instance.tags);
                $tags.forEach(tag => {
                  if (tag.Key == 'Name') {
                    $name = tag.Value;
                  } else {
                    $tmp_tags.push(tag.Value);
                  }
                });
              } else {
                $tmp_tags.push(instance.key_name);
                $name = instance.key_name;
              }
              instance.instance_title = $name;


            });
            this.awsInstanceLists = data.response.instances;
          }
          else {
            this.notifier.notify("error", data.message);
          }
        },
        error => {
          this.spinnerService.hide();
          this.notifier.notify("error", error.message);
        });
      instance_ids = awsSchedule.instance_id.split(',');
    } else {
      this.showInstanceList = false;
      this.showGroupList = false;
    }

    editdays = awsSchedule.day.split(',');
    this.awsScheduleForm.patchValue({
      schedule_by: awsSchedule.schedule_by,
      scheduler_name: awsSchedule.scheduler_name,
      group_id: group_ids,
      instance_id: instance_ids,
      turn_status: awsSchedule.turn_status,
      time: awsSchedule.time,
      day: editdays,
      guid: awsSchedule.guid
    });

  }

  editSchedule() {
    this.awsScheduleFormSubmitted = true;

    // stop here if form is invalid
    if (this.awsScheduleForm.invalid) {
      return;
    }
    this.spinnerService.show();
    this.DataService.updateAwsScheduler(this.awsScheduleForm.value).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {

          //this.awsAccessKeys = data.response.awsAccessKeys;
          this.notifier.notify("success", "AWS key saved.");

          this.awsScheduleForm.reset();
          this.callgetAllAwsScheduler();
          /* location.reload(); */
        }
        else {
          this.spinnerService.hide();
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });
  }
  deleteAwsScheduler(guid) {
    this.spinnerService.show();
    this.DataService.deleteAwsScheduler(guid).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          //this.awsAccessKeys = data.response.awsAccessKeys;
          this.notifier.notify("success", "Schedule deleted.");
          this.callgetAllAwsScheduler();
        }
        else {
          this.spinnerService.hide();
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });

  }
}
