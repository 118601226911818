import { Component, OnInit,OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
@Component({
  selector: 'app-instance-detail',
  templateUrl: './instance-detail.component.html',
  styleUrls: ['./instance-detail.component.css']
})
export class InstanceDetailComponent implements OnInit {
  guid: string;
  errorMessage: string;
  aws_instance_detail:any={};
  awsInstanceBlockDeviceMappings:any=[];
  awsInstanceTags:any=[];
  awsNetworkInterfacesPrivateIpAddresses:any=[];
  awsNetworkInterfacesSecurityGroups:any=[];
  
  
  constructor(private route: ActivatedRoute,private DataService: DataService) {
   }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.guid = params.get("guid")
    })
    this.DataService.getInstanceDetail({"instanceGuid":this.guid}).subscribe(
      data => {
        console.log(data);
        //debugger;    
        if (data.message == "success") {
          var $name = "";
          var $tags = [];
         
          data.response.awsInstanceDetails.forEach(instance => {
            var $name = "";
            var $tmp_tags = [];
            if (Object.keys(JSON.parse(instance.tags)).length > 0) {
              $tags = JSON.parse(instance.tags);
              $tags.forEach(tag => {
                if (tag.Key == 'Name') {
                  $name = tag.Value;
                } else {
                  $tmp_tags.push(tag.Value);
                }
              });
            } else {
              $tmp_tags.push(instance.key_name);
              $name = instance.key_name;
            }
            instance.instance_title = $name;
            instance.tmp_tags = $tmp_tags;
          });

          this.aws_instance_detail = data.response.awsInstanceDetails[0];
          this.awsInstanceBlockDeviceMappings = data.response.awsInstanceBlockDeviceMappings;
          this.awsInstanceTags = data.response.awsInstanceTags;
          this.awsNetworkInterfacesPrivateIpAddresses = data.response.awsNetworkInterfacesPrivateIpAddress;
          this.awsNetworkInterfacesSecurityGroups = data.response.awsNetworkInterfacesSecurityGroups;
          
        }
        else {
          this.errorMessage = data.message;
        }
      },
      error => {
        this.errorMessage = error.message;
      });

    console.log(this.guid);
  }

}
