import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { AppComponent } from "../app.component";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    private appComponent:AppComponent,
    private router: Router,
   ) {
     
   }

  ngOnInit() {
  }
  logout()
  {
    localStorage.removeItem('userInfo');
    this.appComponent.isUserLoggedIn = false;
    this.router.navigate(['/login'])
  }
}
