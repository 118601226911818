import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { InstanceDetailComponent } from './instance-detail/instance-detail.component';
import { AuthGuard } from './auth.guard';
import { AwsInstancesComponent } from './aws-instances/aws-instances.component';
import { AwsGroupsComponent } from './aws-groups/aws-groups.component';
import { AwsSchedularComponent } from './aws-schedular/aws-schedular.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'logout',
    component: LoginComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'LogOut'
    }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Dashboard Page'
    },
  },
  {
    path: 'aws-instances/:guid', component: AwsInstancesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
    ]
  },
  {
    path: 'instance-details/:guid', component: InstanceDetailComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
    ]
  },
  {
    path: 'aws-groups',
    component: AwsGroupsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Add Groups'
    }
  },
  {
    path: 'aws-schedular',
    component: AwsSchedularComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create Schedule'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Add User Page'
    }
  },

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }