import { Component, OnInit,ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { NotifierService } from "angular-notifier";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-aws-groups',
  templateUrl: './aws-groups.component.html',
  styleUrls: ['./aws-groups.component.css']
})
export class AwsGroupsComponent implements OnInit {
  @ViewChild('closebutton',{static: true}) closebutton;
  @ViewChild('editclosebutton',{static: true}) editclosebutton;

  awsGroups: any = [];
  errorMessage: string;
  awsGroupForm: FormGroup;
  awsGroupFormSubmitted = false;
  private readonly notifier: NotifierService;
  constructor(private DataService: DataService, private notifierService: NotifierService, private spinnerService: Ng4LoadingSpinnerService, private formBuilder: FormBuilder, private modalService: NgbModal) {
    this.notifier = notifierService;
   }

  ngOnInit() {
    this.callGetAllAwsGroups();
    this.awsGroupForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      guid:[]
    });
  }
  get f() { return this.awsGroupForm.controls; }
  addGroups()
  {
    this.awsGroupFormSubmitted = true;

    // stop here if form is invalid
    if (this.awsGroupForm.invalid) {
      return;
    }
    this.spinnerService.show();
    this.DataService.addAwsInstanceGroup(this.awsGroupForm.value).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          
          //this.awsAccessKeys = data.response.awsAccessKeys;
          this.notifier.notify("success", "AWS key saved.");
          this.closebutton.nativeElement.click();
          this.callGetAllAwsGroups();
          this.awsGroupForm.reset();
          //location.reload();
        }
        else {
          this.spinnerService.hide();
          this.errorMessage = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });
  }
  callGetAllAwsGroups()
  {
    this.spinnerService.show();
    this.DataService.getAwsInstanceGroups().subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          this.awsGroups = data.response.awsInstanceGroups;
        }
        else {
          this.awsGroups = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });
  }
  editModal(awsGroup) {
    this.awsGroupForm.patchValue({
      name: awsGroup.name,
      description: awsGroup.description,
      guid: awsGroup.guid
     });
    
   }

   editGroup()
   {
    this.awsGroupFormSubmitted = true;

    // stop here if form is invalid
    if (this.awsGroupForm.invalid) {
      return;
    }
    this.spinnerService.show();
    this.DataService.updateAwsInstanceGroup(this.awsGroupForm.value).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          
          //this.awsAccessKeys = data.response.awsAccessKeys;
          this.notifier.notify("success", "AWS key saved.");
          this.editclosebutton.nativeElement.click();
          this.callGetAllAwsGroups();
          //location.reload();
        }
        else {
          this.spinnerService.hide();
          this.errorMessage = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });
   }

   deleteAwsInstanceGroup(guid)
  {
    this.spinnerService.show();
    this.DataService.deleteAwsInstanceGroup(guid).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          //this.awsAccessKeys = data.response.awsAccessKeys;
          this.notifier.notify("success", "group deleted.");
          this.callGetAllAwsGroups();
        }
        else {
          this.spinnerService.hide();
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });

  }
}
