import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { NotifierService } from "angular-notifier";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
@Component({
  selector: 'app-aws-instances',
  templateUrl: './aws-instances.component.html',
  styleUrls: ['./aws-instances.component.css']
})
export class AwsInstancesComponent implements OnInit {

  @ViewChild('closebutton', { static: true }) closebutton;

  state = "running";
  errorMessage: string;
  model: any = {};
  awsInstances: any = [];
  data_filter: string;
  aws_key_guid: string;
  awsInstanceGroupForm: FormGroup;
  awsGroups: any;
  aws_instance_guid: any;
  index: any;
  instanceGroups: any;

  private readonly notifier: NotifierService;
  constructor(private DataService: DataService, private formBuilder: FormBuilder, private notifierService: NotifierService, private spinnerService: Ng4LoadingSpinnerService, private route: ActivatedRoute) {
    this.data_filter = "running";
    this.notifier = notifierService;
  }

  ngOnInit() {

    this.awsInstanceGroupForm = this.formBuilder.group({
      // The formArray, empty 
      awsGroupGuid: new FormArray([]),
    });

    this.route.paramMap.subscribe(params => {
      this.aws_key_guid = params.get("guid")
    })
    this.callGetAllInstancesSp(this.aws_key_guid, this.data_filter);


  }


  messages = [];
  options = []
  selected = []

  addToGroup = function (aws_instance_guid, index, aws_instance_group_ids) {
    this.selected = [];
    this.messages = [];
    this.awsInstanceGroupForm.reset();
    this.index = index;
    this.aws_instance_guid = aws_instance_guid;



    this.options = this.instanceGroups;

    if (aws_instance_group_ids) {
      this.instanceGroups = aws_instance_group_ids.split(',');
      this.selected = aws_instance_group_ids.split(',');
    } else {
      this.instanceGroups = [];
      this.selected = [];
    }

    this.callGetAllAwsGroups();
  }


  // check if the item are selected
  checked(item) {
    if (this.selected.indexOf(item) != -1) {
      return true;
    }
  }

  // when checkbox change, add/remove the item from the array
  onChange(checked, item) {
    if (checked) {
      this.selected.push(item);
    } else {
      this.selected.splice(this.selected.indexOf(item), 1)
    }
  }

  save() {
    this.messages.push(this.selected.sort());
    var formData = {
      awsInstanceDetailGuid: this.aws_instance_guid,
      awsInstanceGroupGuid: (this.messages)
    }
    console.log(formData);
    this.spinnerService.show();
    this.DataService.assignAwsInstanceGroupGuid(formData).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        this.closebutton.nativeElement.click();
        if (data.message == "success") {
          this.notifier.notify("success", "Group Assigned.");
          this.callGetAllInstancesSp(this.aws_key_guid, this.state);
        }
        else {
          this.errorMessage = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });
  }




  filterInstances = function (state) {
    this.state = state;
    if (state == "all") {
      this.callGetAllInstancesSp(this.aws_key_guid, null);
    } else {
      this.callGetAllInstancesSp(this.aws_key_guid, state);
    }

  }

  callGetAllInstancesSp = function (aws_key_guid, filter) {
    this.spinnerService.show();
    this.DataService.getAllInstances(aws_key_guid, filter).subscribe(
      data => {
        this.spinnerService.hide();
        //console.log(data.response.instances);
        //debugger;    
        if (data.message == "success") {
          var $tags = [];
          data.response.instances.forEach(instance => {
            var $name = "";
            var $tmp_tags = [];
            // console.log(instance.tags," => ",Object.keys(JSON.parse(instance.tags)).length);
            if (Object.keys(JSON.parse(instance.tags)).length > 0) {
              $tags = JSON.parse(instance.tags);
              $tags.forEach(tag => {
                if (tag.Key == 'Name') {
                  $name = tag.Value;
                } else {
                  $tmp_tags.push(tag.Value);
                }
              });
            } else {
              $tmp_tags.push(instance.key_name);
              $name = instance.key_name;
            }
            instance.instance_title = $name;
            instance.tmp_tags = $tmp_tags;
            if (instance.group_names) {
              instance.aws_groups = instance.group_names.split(',');
            } else {
              instance.aws_groups = "";
            }

          });

          this.awsInstances = data.response.instances;
        }
        else {
          this.errorMessage = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });
  }

  stopInstance = function (instance_guid) {
    this.spinnerService.show();
    this.DataService.stopInstance(instance_guid, this.aws_key_guid).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          this.notifier.notify("success", "Instance stopped successfully");

          this.callGetAllInstancesSp(this.aws_key_guid, "running");
        }
        else {
          this.errorMessage = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.errorMessage = error.message;
        this.notifier.notify("error", error.message);
      });
  }
  startInstance = function (instance_guid) {
    this.spinnerService.show();
    this.DataService.startInstance(instance_guid, this.aws_key_guid).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          this.notifier.notify("success", "Instance started successfully");

          this.callGetAllInstancesSp(this.aws_key_guid, "stopped");
        }
        else {
          this.errorMessage = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.errorMessage = error.message;
        this.notifier.notify("error", error.message);
      });
  }
  refreshInstances = function () {
    this.spinnerService.show();
    this.DataService.refreshInstances(this.aws_key_guid).subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          this.notifier.notify("success", "Instance refreshed successfully");

          this.callGetAllInstancesSp(this.aws_key_guid, "running");
        }
        else {
          this.errorMessage = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.errorMessage = error.message;
        this.notifier.notify("error", error.message);
      });
  }


  callGetAllAwsGroups() {
    this.spinnerService.show();
    this.DataService.getAwsInstanceGroups().subscribe(
      data => {
        this.spinnerService.hide();
        //debugger;    
        if (data.message == "success") {
          this.awsGroups = data.response.awsInstanceGroups;


        }
        else {
          this.awsGroups = data.message;
          this.notifier.notify("error", data.message);
        }
      },
      error => {
        this.spinnerService.hide();
        this.notifier.notify("error", error.message);
      });
  }
}


