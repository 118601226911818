import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import{ GlobalConstants } from './common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  Url :string;
  token : string;
  header : any;
  constructor(private http : HttpClient) {
    //this.Url = 'http://34.231.82.56:3005/api/';
    //this.Url = 'http://192.168.2.44:3005/api/';

    this.Url = GlobalConstants.apiURL;
    const headerSettings: {[name: string]: string | string[]; } = {};
    this.header = new HttpHeaders(headerSettings);
  }
  getAllInstances(aws_key_guid,filter){
    return this.http.post<any>(this.Url+'getAllAwsInstanceListing',{user_guid:aws_key_guid,dataLimit:500,dataOrder:'desc',dataOffset:0,'filter_col':filter},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   getInstanceDetail(model : any){
    return this.http.post<any>(this.Url+'getAwsInstaceDetails',model,{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   startInstance(model : any,aws_key_guid){
    return this.http.post<any>(this.Url+'startAwsInstances',{'instanceGuid':model,"awsUserAccessKeyGuid":aws_key_guid},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   stopInstance(model : any,aws_key_guid){

    return this.http.post<any>(this.Url+'stopAwsInstances',{'instanceGuid':model,"awsUserAccessKeyGuid":aws_key_guid},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   refreshInstances(aws_key_guid){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    return this.http.post<any>(this.Url+'describeAwsInstances',{
      "user_guid":parseUserInfo.guid,
      "awsUserAccessKeyGuid":aws_key_guid
    },{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   getAwsAccessKeys(){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    return this.http.post<any>(this.Url+'getAwsAccessKeys',{'user_guid':parseUserInfo.guid},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   addUserAwsKeys(model : any){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    model.userGuid = parseUserInfo.guid;
    console.log(model);
    return this.http.post<any>(this.Url+'addUserAwsKeys',model,{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   deleteUserAwsKeys(guid){
    return this.http.post<any>(this.Url+'deleteUserAwsKeys',{'guid':guid},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   getAwsInstanceGroups(){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    return this.http.post<any>(this.Url+'getAwsInstanceGroups',{'userGuid':parseUserInfo.guid},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   addAwsInstanceGroup(model : any){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    model.userGuid = parseUserInfo.guid;
    return this.http.post<any>(this.Url+'addAwsInstanceGroup',model,{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   updateAwsInstanceGroup(model : any){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    model.userGuid = parseUserInfo.guid;
    return this.http.post<any>(this.Url+'updateAwsInstanceGroup',model,{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   assignAwsInstanceGroupGuid(model : any){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    model.userGuid = parseUserInfo.guid;
    return this.http.post<any>(this.Url+'assignAwsInstanceGroupGuid',model,{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   getAllAwsInstancesForSchedular(){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    return this.http.post<any>(this.Url+'getAllAwsInstancesForSchedular',{user_guid:parseUserInfo.guid},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   addAwsScheduler(model : any){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    model.userGuid = parseUserInfo.guid;
    return this.http.post<any>(this.Url+'addAwsScheduler',model,{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }

   getAllAwsScheduler(){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    return this.http.post<any>(this.Url+'getAllAwsScheduler',{user_guid:parseUserInfo.guid},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   deleteAwsScheduler(guid){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    return this.http.post<any>(this.Url+'deleteAwsScheduler',{'guid':guid,'userGuid':parseUserInfo.guid},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   deleteAwsInstanceGroup(guid){
    return this.http.post<any>(this.Url+'deleteAwsInstanceGroup',{'guid':guid},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   updateAwsScheduler(model : any){
    var parseUserInfo = JSON.parse(localStorage.userInfo);
    model.userGuid = parseUserInfo.guid;
    return this.http.post<any>(this.Url+'updateAwsScheduler',model,{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }

   getGroupName(model : any)
   {
    return this.http.post<any>(this.Url+'getGroupName',{'groupGuid':model},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
   getInstanceName(model : any)
   {
    return this.http.post<any>(this.Url+'getInstanceName',{'instanceGuid':model},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })});
   }
}
