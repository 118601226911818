import { Component, Input } from '@angular/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @Input() public title = 'Home';
  @Input() public isUserLoggedIn =false;
  constructor(
    private router: Router,
   ) {
     if(localStorage.userInfo)
     {
      this.isUserLoggedIn = true;
     }
   }
  
}
